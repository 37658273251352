// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton,
	TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed,
	TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


/*
	T W I T T E R  posts as a iFrame style DIV
*/

class Twitter extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_twitter: null,
					_height: this.props.height || 400
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
			var APIUrl = process.env.API + '/setting/' + 'TWITTER';
			MyFetchGet(APIUrl, 3)
			    .then( response => response.json() )
			    .then( Twitter => {
							if (this._isMounted) {
								this.setState( {_twitter : Twitter} );
							}
			    })
					.then( () => {
							// this._isMounted = false;
					})
					.catch( /* It should retry !! */ )
		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

		// Not ready yet...
		if ( _.isEmpty( this.state._twitter) ) return (<div></div>);

				return (

						<TwitterTimelineEmbed
						  sourceType="profile"
						  screenName={ this.state._twitter }
						  options={{ height: this.state._height }}
						/>

				);
	}

}

export default Twitter;
