// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import Slider from "react-slick";

/*
	v I M A G E    C A R O U S E L  v1.0

	Prop - 	id of Carousel + width/height

*/

class Vimage extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_reels: null,
					_height: this.props.height || '400',
					_width: this.props.width || '100%',
					_slides: this.props.slides || 4
        };
	}

	componentDidMount () {

		this._isMounted = true;

		var APIUrl = 'https:///vimage.vadoo.co.uk/api/reels/get/' + this.props.id;
    MyFetchGet( APIUrl, 5)
		    .then( response => response.json() )
		    .then( reels => {
							// console.log(reels);
							this.setState( {_reels : reels} );
		    })

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	onClickImage(url) {
		window.open(url);
	}

	render () {

		var settings = {
			slidesToShow: this.state._slides,
			arrows: true,
			dots: false,
			autoplay: true,
			autoplaySpeed: 3000,
			responsive: [
							{
								breakpoint: 1024,
								settings: {
									slidesToShow: 2
								}
							},
			        {
			          breakpoint: 640,
			          settings: {
			            slidesToShow: 1
			          }
			        }
			      ]
		}


		// Not ready yet...
		if ( _.isEmpty( this.state._reels) )
				return (<div></div>);

			return (

						<Slider {...settings}>

								{ (this.state._reels.links).map( (image, key) => {

										return(
											<div key={key}>
													<img src={image.meta_image_live} className="image-responsive pointer"
														onClick={ evt => this.onClickImage(image.linkURL_live) } />
											</div>
										)

								})}

						</Slider>

			);
	}

}

export default Vimage;
