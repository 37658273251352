// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';

import FeaturedProducts from 'FeaturedProducts';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';

import MySelect from 'MySelect';

class CustomPageBlocks extends React.Component {

	componentDidMount() {
		// Scroll Top after render()
	  window.scrollTo(0, 0)
	}

	render () {
		return (
			<div className="flex-site">
        <div className="site-content">

						<Nav />
						<MegaMenu />
						<ProductSearch />

							{/* Art Gallery */}

						    		{ this.props.pagename == 'ArtGallery' &&
												(

													<div className="grid-x grid-margin-x" >
														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>

														<div className="cell small-12 medium-12 large-12">
																<SlickCarousel carousel="Art Gallery" slides={4} className="expand-hover" />
														</div>

														{/* Force Blank line */}
														<div className="cell small-12">&nbsp;</div>
													</div>

												)
										}

							{/* ....another */}


				</div>
				<Footer />
				<ScrollTop />

			</div>
		);
	}

}

export default CustomPageBlocks;
