// Use new ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CMSImage from 'CMSImage';
import CMSBlock from 'CMSBlock';
import Translate from 'Translate';

class Footer extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_email: '',
					_telephone: '',
					_address: '',
					_instagram: '',
					_facebook: '',
					_twitter: '',
					_youtube: '',
					_linkedin: ''
        };
	}

	componentDidMount() {

		this._isMounted = true;

		//
		//	We can get these aSync
		//

		// 1. Address
		MyFetchGet(process.env.API + '/cms/getblock/' + 'ADDRESS', 3)
        .then( response => response.json() )
        .then( address => {
					if (this._isMounted)
						this.setState( {_address : address } );
					})
					.catch( /* It should retry !! */ )

		// 2a. eMail
		MyFetchGet(process.env.API + '/setting/' + 'EMAIL-ADDRESS', 3)
        .then( response => response.json() )
        .then( email => {
					if (this._isMounted)
						this.setState( {_email : email } );
		    })
				.catch( /* It should retry !! */ )

		// 2b. Telephone
		MyFetchGet(process.env.API + '/setting/' + 'TELEPHONE', 3)
        .then( response => response.json() )
        .then( telephone => {
					if (this._isMounted)
						this.setState( {_telephone : telephone } );
		    })
				.catch( /* It should retry !! */ )

		// 3. Instagram
		MyFetchGet(process.env.API + '/setting/' + 'INSTAGRAM', 3)
        .then( response => response.json() )
        .then( instagram => {
					if (this._isMounted)
						this.setState( {_instagram : instagram } );
		    })
				.catch( /* It should retry !! */ )

		// 4. Facebook
		MyFetchGet(process.env.API + '/setting/' + 'FACEBOOK', 3)
        .then( response => response.json() )
        .then( facebook => {
					if (this._isMounted)
						this.setState( {_facebook : facebook } );
		    })
				.catch( /* It should retry !! */ )

		// 5. Twitter
		MyFetchGet(process.env.API + '/setting/' + 'TWITTER', 3)
        .then( response => response.json() )
        .then( twitter => {
					if (this._isMounted)
						this.setState( {_twitter : twitter } );
		    })
				.catch( /* It should retry !! */ )

		// 6. Youtube
		MyFetchGet(process.env.API + '/setting/' + 'YOUTUBE', 3)
        .then( response => response.json() )
        .then( youtube => {
					if (this._isMounted)
						this.setState( {_youtube : youtube } );
		    })
				.catch( /* It should retry !! */ )

		// 7. LinkedIn
		MyFetchGet(process.env.API + '/setting/' + 'LINKEDIN', 3)
        .then( response => response.json() )
        .then( linkedin => {
					if (this._isMounted)
						this.setState( {_linkedin : linkedin } );
		    })
				.catch( /* It should retry !! */ )

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	render () {

		return (
			<div>
				<div className="page-footer grid-x">

					{/* ----------------------------------------------------------------------
							L H S - Address, email, tele  etc..
							pixel-nudge-down-... often required with text next to FontAwesomeIcons
					*/}

					<div className="cell small-12 medium-12 large-4 footer-block">
							<span className="larger-text spaced-text">
									<Translate text="CONTACT" />
									&nbsp;
									<span className="very-large-text highlight-colour bold">|</span>
									&nbsp;
									<Translate text="US" />
							</span>
							<br /><br />

							{ !_.isEmpty(this.state._address)
									&& <div dangerouslySetInnerHTML={{__html: this.state._address.content}} /> }

							<br />

							<FontAwesomeIcon icon="at" size="2x" className="highlight-colour float-left" />
							{ !_.isEmpty(this.state._email) && <a href={'mailto:'+this.state._email}
								className="dark-grey-force pixel-nudge-down-5px large-lhs-space">{this.state._email}</a> }

							<br /><br />
							{ !_.isEmpty(this.state._telephone) &&
								<span>
										<FontAwesomeIcon icon="mobile-alt" size="2x"
											className="dark-grey-force float-left" /> &nbsp;&nbsp;&nbsp;
										<span className="force-black large-lhs-space">
												{this.state._telephone}
										</span>
								</span> }

					</div>

					{/* -----------------------------------------
							C E N T R E - Logo + Terms & links etc...
					*/}

					<div className="cell small-12 medium-12 large-4 text-center">
						<CMSImage imageName="Logos/vshop-logo.png" className="logo-footer footer-block"/>

						<Link to="/page/terms-conditions">
							<span className="dark-grey-force"><Translate text="Terms" /></span>
						</Link>
						&nbsp;&nbsp;
						<span className="highlight-colour bold">|</span>
						&nbsp;&nbsp;

						<Link to="/page/delivery-returns">
							<span className="dark-grey-force"><Translate text="Delivery & Returns" /></span>
						</Link>

						&nbsp;&nbsp;
						<span className="highlight-colour bold">|</span>&nbsp;&nbsp;

						<Link to="/page/privacy-policy">
							<span className="dark-grey-force"><Translate text="Privacy" /></span>
						</Link>

						<br /><br />
							<a href="https://vshop.vadoo.co.uk" target="_blank" className="dark-grey-force small-text">
								<Translate text="powered by" /> <b>vShop</b>
							</a>
						<br /><br />

					</div>

					{/* -----------------------------
							R I G H T  - Social Media ...
					*/}

					<div className="cell small-12 medium-12 large-4 footer-block">

								<span className="larger-text spaced-text">
									<span className="very-large-text highlight-colour bold">|</span>
									&nbsp;
									<Translate text="STAY IN TOUCH" />
								</span>

								<br /><br /><br />

								{/*   --------------
											Links to Sites
								*/}

								<div className="grid-x">

										{ !_.isEmpty(this.state._facebook)
												&& <a href={'https://www.facebook.com/' + this.state._facebook}
												target="_blank" className="cell small-2">
												<CMSImage imageName="Logos/icon-facebook.png"
													className="icon-footer monochrome pointer expand-hover"/></a> }

										{ !_.isEmpty(this.state._twitter)
												&& <a href={'https://www.twitter.com/' + this.state._twitter}
												target="_blank" className="cell small-2">
												<CMSImage imageName="Logos/icon-twitter.png"
													className="icon-footer monochrome pointer expand-hover"/></a> }

										{ !_.isEmpty(this.state._instagram)
												&& <a href={'https://www.instagram.com/' + this.state._instagram}
												target="_blank" className="cell small-2">
												<CMSImage imageName="Logos/icon-instagram.png"
													className="icon-footer monochrome pointer expand-hover"/></a> }

										{ !_.isEmpty(this.state._youtube)
												&& <a href={'https://www.youtube.com/' + this.state._youtube}
												target="_blank" className="cell small-2">
												<CMSImage imageName="Logos/icon-youtube.png"
													className="icon-footer monochrome pointer expand-hover"/></a> }

										{ !_.isEmpty(this.state._linkedin)
												&& <a href={'https://www.linkedin.com/' + this.state._linkedin}
												target="_blank" className="cell small-2">
												<CMSImage imageName="Logos/icon-linkedin.png"
													className="icon-footer monochrome pointer expand-hover"/></a> }

								</div>

					</div>
				</div>
			</div>
		);
	}

}

export default Footer;
