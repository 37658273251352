// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';

/*
	F A C E B O O K  posts as a iFrame style DIV
	https://developers.facebook.com/docs/plugins/page-plugin

*/

class Facebook extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_facebook: null
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
			var APIUrl = process.env.API + '/setting/' + 'FACEBOOK';
			MyFetchGet(APIUrl, 3)
			    .then( response => response.json() )
			    .then( Facebook => {
							if (this._isMounted) {
								this.setState( {_facebook : Facebook} );
							}
			    })
					.then( () => {

							const script = document.createElement("script");
			        script.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0&appId=1989004961192090&autoLogAppEvents=1'; // whatever url you want here
			        script.charset = "utf-8";
							script.crossorigin="anonymous";
			        script.async = true;
			        script.onload = function () {

			        };
			        document.head.appendChild(script);

							// this._isMounted = false;
					})
					.catch( /* It should retry !! */ )
		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

				if ( _.isEmpty( this.state._facebook ) ) return (<div></div>);

				return (

					<div>

						<div id="fb-root"></div>
						<div className="fb-page"
							data-href={"https://www.facebook.com/" + this.state._facebook}
							data-tabs="timeline"
							data-width={this.props.width}
							data-height={this.props.height}
							data-small-header="false"
							data-adapt-container-width="true"
							data-hide-cover="false"
							data-show-facepile="true">
							<blockquote cite={"https://www.facebook.com/" + this.state._facebook}
								className="fb-xfbml-parse-ignore">
								<a href={"https://www.facebook.com/" + this.state._facebook}>
									{this.state._facebook}
								</a>
							</blockquote>
						</div>

				</div>

				);
	}

}

export default Facebook;
