// Use ES6 Class

import React from 'react';
import _ from 'lodash';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import Translate from 'Translate';

const userInstagram = require("user-instagram");

import Slider from "react-slick";

/*
	I N S T A G R A M  posts as a Carousel
*/

class Instagram extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_url: '',
					_instagram: null
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// Load initial API Data
		if (this._isMounted) {
			MyFetchGet(process.env.API + '/setting/' + 'INSTAGRAM', 1)
			    .then( response => response.json() )
			    .then( url => {

							// with user-instagram - no longer works Jul 2020 !
							// userInstagram(url)
							// 	.then( Instagram => {
							// 				console.log(Instagram);
							// 				// if (this._isMounted) {
							// 				// 	this.setState( {_instagram : Instagram} );
							// 				// 	this.setState( {_url : url} );
							// 				// }
							// 	});

							// My Version using the PHP Server API
							MyFetchGet(process.env.API + '/social/instagram/' + url, 1)
							    .then( response => response.json() )
							    .then( Instagram => {
											console.log( Instagram );
											if ( !_.isEmpty( Instagram ) ) {
													var digDeep = Instagram.graphql.user.edge_owner_to_timeline_media.edges;
													console.log( digDeep );
													if (this._isMounted) {
														this.setState( {_instagram : digDeep} );
														this.setState( {_url : url} );
													}
											} else {
													console.log(process.env.API + '/social/instagram/' + url + ' failed');
											}
									} );


			    })
					.then( () => {
							// this._isMounted = false;
					})
					.catch( /* It should retry !! */ )
		}

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	onClickPost(shortcode) {
		window.open('https://www.instagram.com/p/' + shortcode);
	}

	render () {

		var settings = {
			slidesToShow: 4,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 1500,
			responsive: [
			        {
			          breakpoint: 1024,
			          settings: {
			            slidesToShow: 2
			          }
			        },
			        {
			          breakpoint: 640,
			          settings: {
			            slidesToShow: 1
			          }
			        }
			      ]
		}

		// Not ready yet...
		if ( _.isEmpty( this.state._instagram) ) return (<div></div>);

				return (
					<div className="center">
							{/* Icon */}
							<a href={'https://www.instagram.com/' + this.state._url} target='_blank'>
								<img src="images/icon-instagram.png" className="expand-hover center"/>
								<br /><br />
								<div className="larger-text bold highlight-colour">
									<Translate text="Click to join us on Instagram" />
								</div>
							</a>

							<br /><br />

							<Slider {...settings} >

									{this.state._instagram.map( (post, key) => {

										return (
											<div key={key}>
												<img className="pointer image-responsive" src={post.node.display_url}
													onClick={ evt => this.onClickPost(post.node.shortcode) } />
											</div>
										)

									})}

							</Slider>

					</div>
				);
	}

}

export default Instagram;
