// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';									// Required by some|most PSPs
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MyFetchGet, MyFetchPost } from 'MyFetch';
import { HashRouter, Route, Redirect } from 'react-router-dom';
import Translate from 'Translate';
import PleaseWait from 'PleaseWait';

import Popup from "reactjs-popup";
import { BackgroundImage } from "react-image-and-background-image-fade";

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class PayVendo extends React.Component {

	constructor(props, defaultProps) {
		    super(props, defaultProps);
        this.state = {
						_payment: false,
						_openVendoCheckout: false,
						_confirmedVendoCheckout: false,
						_pleaseWait: false,
						_javascriptPopup: false,
						_vendoKey: '',
						_ipv4: '',

						/* Card Details */
						_cardNumber: '',
						_cardExpiryMonth: '',
						_cardExpiryYear: '',
						_cardCVV: '',

						/* Address Details */
						_title: '',
						_givenName: '',
						_familyName: '',
						_address: '',
						_city: '',
						_county: '',
						_postCode: '',
						_phone: '',

						addressKeys: [],          	// Array of ALL allowed address Keys
						addressData: []           	// Array of THIS users addresses

        };
				// Required ?
				this.loadVendoPopup = this.loadVendoPopup.bind(this);

				// We do need to bind this to these external class f()s though
				vendoOnSuccess = vendoOnSuccess.bind(this);
				vendoOnFail = vendoOnFail.bind(this);
	}

	componentDidMount () {

		this._isMounted = true;

		this.loadVendoPopup();

		// Establish if Vendo is being used...
		if (this._isMounted) {

				// Required https://
				MyFetchGet( 'https://api.ipify.org?format=json', 3)
	        .then( response => response.json() )
	        .then( data => {
	          if (this._isMounted) {
							// console.log( data );
	            this.setState( { _ipv4: data.ip } );
						}
	        })
	        .catch( /* It should retry !! */ )

				// Determine if Vendo is installed and can be used
				MyFetchGet( process.env.API + '/setting/' + 'VENDO-LIVE', 3 )
		        .then( response => response.json() )
		        .then( setting => {

								if ( !_.isEmpty(setting) && setting != -1  ) {			// Vendo not installed

												if ( setting == 0 ) {
														var _apiVendoKey = process.env.API + '/setting/' + 'VENDO-KEY-TEST';
														// load some test card settings
														this.setState( { _cardNumber: '4111111111111111' } );
														this.setState( { _cardExpiryMonth: '12' } );
														this.setState( { _cardExpiryYear: '2025' } );
														this.setState( { _cardCVV: '123' } );
														this.setState( { _givenName: 'John' } );
														this.setState( { _familyName: 'Doe' } );
														this.setState( { _address: '344a High Street' } );
														this.setState( { _city: 'Orpington' } );
														this.setState( { _county: 'Kent' } );
														this.setState( { _postCode: 'BR6 0NQ' } );
														this.setState( { _phone: '07914755330' } );
												} else
														var _apiVendoKey = process.env.API + '/setting/' + 'VENDO-KEY-LIVE';

												MyFetchGet( _apiVendoKey, 3 )
										        .then( response => response.json() )
										        .then( _id => {
																	// console.log('Vendo | Key: ' + _id);
																	this.setState( { _vendoKey: _id } );

																	// -- F I N A L L Y --
																	// ready to go....
																	// console.log('Open Vendo Popup Checkout');
																	this.setState( { _openVendoCheckout: true } );
														})
									      		.catch( /* Should try again */ );

								}

						});
				}

	}

	componentWillUnmount () {
			// Cancel any services to stop memory issues
			this._isMounted = false;
	}

	loadVendoPopup () {

		// console.log('Load vShop/Vendo popup.js...');
		const script = document.createElement("script");
    script.src = "https://vshop.vadoo.co.uk/popup/v1/popup.js";
    document.getElementsByTagName("head")[0].appendChild(script);

    if (script.readyState) {
		      // IE
		      script.onreadystatechange = () => {
				        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
					          script.onreadystatechange = null;
										// console.log('Legacy | popup.js loaded');
					          this.setState( { _javascriptPopup: true } );
				        }
		      };
    } else {
		      // Chrome etc...
		      script.onload = () => {
							// console.log('Modern | popup.js loaded');
		        	this.setState( { _javascriptPopup: true } );
		      };
    }

	}

	openVendoCheckout ( evt ) {

		// Open popoup to collect required data
		var _data = {
					key: this.state._vendoKey,
					order_reference: this.props.cartID,
					amount: 100 * (this.props.cartValue*1 + this.props.deliveryCharge*1 + this.props.VATCharge*1),
					currency: this.props.currency,

					email: this.props.userEmail,
					first_name: this.state._givenName,
					last_name: this.state._familyName,
					phone: this.state._phone,
					address: this.state._address,
					city: this.state._city,
					county: this.state._county,
					postcode: this.state._postCode,

					card_number: this.state._cardNumber,
					card_expiry_month: this.state._cardExpiryMonth,
					card_expiry_year: this.state._cardExpiryYear,
					card_CVV: this.state._cardCVV,

					description: 'vShop Cart'
				};

		// Open the lightbox Popup
		// Note that I am making On..f() external to this class for scope
		// otherwise vendoPopup won't see them !
		vendoPopup( _data, vendoOnSuccess, vendoOnFail );

	}

	//
	// ---------------------------------------------------------------------------
	//

	render () {

		{/* _promise is a state flag which triggers <Redirect to PaymentReceived ../>. This is
			set when the user has the vendoOnSuccess shows a payment status of 1/true */}

		if (this.state._payment) {
			{/* This is just a page refresh - React JS style */}
			var _amount = this.props.cartValue * 1 + this.props.deliveryCharge * 1 + this.props.VATCharge * 1;
			return (<Redirect to={`/payment-received/${_amount}`} push />);
		}

		if ( this.state._pleaseWait ) return(<PleaseWait />);

		if ( !this.state._javascriptPopup )			// Wait...debug
				return (<div></div>);

		if ( !this.state._openVendoCheckout )			// Wait...debug
				return (<div></div>);

		// Shows the button for checkout
		return (
			<div className="custom-checkout-button">
					<img src="https://vshop.vadoo.co.uk/popup/buttons/pay-vendo-checkout.png" className="pointer"
						onClick={ evt => this.openVendoCheckout( evt ) }/>
			</div>
		);

	}

}

// Set the default values
PayVendo.defaultProps = {
	userID: reduxStore.getState().userID,
	userEmail: reduxStore.getState().userEmail,
  cartID: reduxStore.getState().cartID,
	cartValue: reduxStore.getState().cartValue
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userID: state.userID,
			userEmail: state.userEmail,
			cartID: state.cartID,
			cartValue: state.cartValue,
    }
}

// External visible callbacks for vendoPopup; note that we have bound (this) to this f()

function vendoOnSuccess( _reply ) {

	this.setState( { _pleaseWait: true } );
	// console.log( 'Call Vendo API for payment with _reply data from JS Popup' );
	// console.log( _reply );

	// console.log( 'IP V4 Address : ' + this.state._ipv4 );

	// We can use this as see above bind().
	var _data = {
		cartID: 		this.props.cartID,
		amount: 		100 * (this.props.cartValue*1 + this.props.deliveryCharge*1 + this.props.VATCharge*1),
		currency: 	this.props.currency,

		email: 			_reply.email,
		phone: 			_reply.phone,

		first_name: _reply.first_name,
		last_name: 	_reply.last_name,

		address: 		_reply.address,
		city: 			_reply.city,
		county: 		_reply.county,
		postcode: 	_reply.postcode,
		ip_address: this.state._ipv4,

		card_number: 				_reply.card_number,
		card_expiry_month: 	_reply.card_expiry_month,
		card_expiry_year: 	_reply.card_expiry_year,
		card_CVV: 					_reply.card_CVV,

		description: 'vShop Cart'
	};

	MyFetchPost( process.env.API + '/payments/vendo', _data, 1)
		.then( response => response.json() )
		.then( reply => {
					this.setState( { _pleaseWait: false } );
					console.log( reply );
					// and now show this as a `payment` -> redirects page in `render`
					if ( reply.response )
						this.setState( { _payment: true } );
					else {
						console.log( 'Payment declined' );
					}
		});

}

function vendoOnFail( _reply ) {
	// console.log('Arrived at vendoOnFail');
	if ( _reply )
		console.log( _reply );
	// ....
}

export default connect(mapStateToProps)(PayVendo);
