// Use ES6 Class

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'Nav';
import MegaMenu from 'MegaMenu';
import Translate from 'Translate';

import FeaturedProducts from 'FeaturedProducts';
import ProductSearch from 'ProductSearch';
import Footer from 'Footer';
import ScrollTop from 'ScrollTop';
import HomePopup from 'HomePopup';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import YouTubeVideo from 'YouTubeVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';
import Vimage from 'Vimage';

import Instagram from 'Instagram';
import Twitter from 'Twitter';
import Facebook from 'Facebook';

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';
import ReactPixel from 'react-facebook-pixel';
import MySelect from 'MySelect';

// Animations if required
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class Home extends React.Component {

	componentDidMount() {

		// Scroll Top after render() - same as a jQuery Doc Ready
	  window.scrollTo(0, 0);

		setReduxValue(reduxStore, 'SET_SEARCH_TEXT', '' );
		var action1 = {
				type: 'SET_SEARCH_TEXT',
				searchText: ''
		};
		// Note that we apply the dispatch to the props: mapStateToProps(state)
		this.props.dispatch(action1);
		// console.log('Search Box should be clear ?');
		$('#ThisProductSearch').val('');

		//
		//	Any bespoke onMount...
		//

		// ReactPixel.init('1957476027602865');

	}

	render () {

		return (
			<div className="flex-site">
				<IPLog URL="/#/" />
				<HomePopup />
				<div className="site-content">
						<Nav />
						<MegaMenu />
						<ProductSearch />

						<div className="grid-x grid-margin-x">

							{/*
											*********************
													B E S P O K E
											*********************
							*/}

							{/* Video Block */}

							{/* <div className="cell small-10 small-offset-1">
									<CMSVideo videoName="Video/keep-maiden-moving.mp4" loop={true} />
							</div> */}

							{/* Force Blank line */}
							{/* <div className="cell small-12">&nbsp;</div> */}

							{/* L H S */}
							<Fade left delay={1000}>
								<div className="cell small-12 medium-12 large-6">
						    		<CMSBlock blockTitle="Home-Hero" className="flow-text" />
						    </div>
							</Fade>

							{/* R H S */}
							<Fade right delay={1000}>
								<div className="cell small-12 medium-12 large-6">
						    		<CMSImage imageName="Home/red-wine-glasses.jpg" className="image-bottom-margin"/>
						    </div>
							</Fade>

							{/* Force Blank line */}
							<div className="cell small-12">&nbsp;</div>

							{/* Demo Parallax */}
							<div className="cell small-12">
									<CMSParallax imageName="Backgrounds/uk-vineyard.jpg" content="Visit-A-Vineyard"/>
							</div>

							{/* Encourage Sign Up */}
							{ this.props.userID < 1 &&
							<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 large-6 large-offset-3 center">
					    		<CMSBlock blockTitle="Home-Sign-Up" className="flow-text" />
									<Link to="/register">
		                <button className="hollow button secondary">
											<Translate text="Register" />
										</button>
		              </Link>
					    </div> }

							{/* Encourage Sign Up */}
							{ this.props.userID > 0 &&
							<div className="cell small-10 small-offset-1 medium-8 medium-offset-2 large-6 large-offset-3 center">
					    		<CMSBlock blockTitle="Signed-Up" className="flow-text" />
					    </div> }

							{/* Force Blank line */}
							<div className="cell small-12">&nbsp;</div>

							{/* Block #2 */}

							<div id="GoogleMapContainer" className="cell small-12 medium-12 large-4 center">
									<CMSImage imageName="Home/online-retail.jpg" className="image-bottom-margin"/>
					    		<CMSBlock blockTitle="Home-Cellars" className="flow-text" />
									<GoogleMap />
					    </div>

							{/* Block #3 */}

							<div className="cell small-12 medium-12 large-4 center">
									<CMSBlock blockTitle="Home-Gift-Ideas" className="flow-text" />
									<CMSImage imageName="Home/wine-selection.png" className="image-bottom-margin"/>
					    		<CMSBlock blockTitle="Home-Wine-Selection" className="flow-text" />
					    </div>

							{/* Block #4 */}

							<div className="cell small-12 medium-12 large-4 center">
									<CMSImage imageName="Home/red-wine-sample.jpg" className="image-bottom-margin"/>
					    		<CMSBlock blockTitle="Home-Reserve" className="flow-text" />
									<div className="grid-x">
										<div className="cell small-12"><br /><br /></div>
										<div className="small-6 small-offset-3 light-grey-background">
											<Link to="/page/wine-tasting">
												<AnimateCard id="WineTasting" front="Home/wine-tasting.png" back="Home/wine-tasting-back.png"/>
											</Link>
										</div>
									</div>
					    </div>

							{/*   --- A N I M A T E D   C A R D S ---  */}

							<div className="cell small-12 medium-12 large-6 large-offset-3 center">
									<CMSBlock blockTitle="Special-Offers" className="flow-text" />
					    </div>
							<div className="cell large-3"></div>

							<div className="cell small-8 small-offset-2 medium-4 medium-offset-0 large-2 large-offset-1 light-grey-background">

									<Link to="/products/BIN-ENDS">
										<AnimateCard id="BinEnds" front="Home/bin-ends.png" back="Home/bin-ends-back.png"/>
									</Link>
									{/* Mobile separator */}
									<div className="white-background "><br /><br /></div>

							</div>

							<div className="cell small-8 small-offset-2 medium-4 medium-offset-0 large-2 large-offset-2 light-grey-background">

									<Link to="/products/SALE-ITEMS">
										<AnimateCard id="ForSale" front="Home/for-sale.png" back="Home/for-sale-back.png"/>
									</Link>
									{/* Mobile separator */}
									<div className="white-background "><br /><br /></div>

							</div>

							<div className="cell small-8 small-offset-2 medium-4 medium-offset-0 large-2 large-offset-2 light-grey-background">

									<Link to="/products/EN-PRIMEUR-WINE">
										<AnimateCard id="EnPrimeur" front="Home/en-primeur.png" back="Home/en-primeur-back.png"/>
									</Link>
									{/* Mobile separator */}
									<div className="white-background "><br /><br /></div>

							</div>

							{/*   ---  F E A T U R E D  ---   */}
							<div className="cell small-10 small-offset-1 medium-10 medium-offset-1 large-6 large-offset-3 center">
								<CMSBlock blockTitle="Featured-Products" className="flow-text" />
							</div>
							<div className="cell small-12">
									<FeaturedProducts />
							</div>

							{/*   P H O T O    G A L L E R Y

									- can't exist as just <SlickCarousel /> as it must have a unique DIV

							*/}

							<div className="cell small-12 medium-12 large-6 large-offset-3 center">
								<CMSBlock blockTitle="Photo-Gallery" className="flow-text" />
							</div>
							<div className="cell small-12 medium-12 large-10 large-offset-1 center">
								<SlickCarousel slides={4} carousel="Drinks"/>
								<br /><br />
							</div>


							{/*   ---  S O C I A L   M E D I A   F E E D S  ---   */}

							{/* <div className="cell small-10 small-offset-1 center">
								<br />
								<Instagram />
								<br />
							</div> */}

							{/* <div className="cell small-8 small-offset-2 medium-4 medium-offset-1">
								<br />
								<Twitter height={600}/>
								<br />
							</div>

							<div className="cell small-8 small-offset-2 medium-4 medium-offset-1">
								<br />
								<Facebook width={500} height={600} />
								<br />
							</div> */}

							{/* <div id="PlayVideo" className="cell small-10 small-offset-1 medium-6 medium-offset-3 center">
								<br />
								<YouTubeVideo id="JrbMNBNMksk" container="PlayVideo" loop={true} />
							</div> */}

							{/* Force Blank lines..
							<div className="cell small-12">&nbsp;<br />&nbsp;<br />&nbsp;</div> */}

							{/*   ---  V I M A G E  ---   */}
							<div className="cell small-12 medium-12 large-6 large-offset-3 center">
								<CMSBlock blockTitle="Vimage-Gallery" className="flow-text" />
							</div>
							<div className="cell small-10 small-offset-1 center">
								<br />
								<Vimage id="116" />
								<br />
							</div>

						</div>
				</div>
				<Footer />
				<ScrollTop />
			</div>
		);
	}

}

// Set the default values
Home.defaultProps = {
	userID: reduxStore.getState().userID,
	searchText: reduxStore.getState().searchText
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			userID: state.userID,
			searchText: state.searchText
    }
}

export default connect(mapStateToProps)(Home);
