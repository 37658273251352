// Use ES6 Class

import React from 'react';
import _ from 'lodash';

import ReactPlayer from 'react-player';

/*
	--------------------------------
	Plays a You Tube Video on its ID
	--------------------------------
*/

class YouTubeVideo extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_TVheight: 0
        };
	}

	componentDidMount () {

		this._isMounted = true;

		// jQuery stuff....

		var _containerWidth = $('#' + this.props.container).width();
		// console.log( _containerWidth );

		if ( this._isMounted )
			this.setState( { _TVheight : _containerWidth*9/16 } );

	}

	componentWillUnmount () {
    // Cancel any services to stop memory issues
		this._isMounted = false;
  }

	render () {

			if ( this.state._TVheight == 0 ) return (<div></div>);

			return (

				<ReactPlayer
							url={"https://www.youtube.com/embed/" +  this.props.id}
							playing={ this.props.play || false }
							loop={ this.props.loop || false }
							controls={true}
							width="100%"
							height="100%"
							style={{ minHeight: this.state._TVheight }}/>

			);

	}

}

export default YouTubeVideo;
