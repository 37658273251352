// Use ES6 Class

import React from 'react';
import Popup from "reactjs-popup";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Translate from 'Translate';

import CMSBlock from 'CMSBlock';
import CMSImage from 'CMSImage';
import CMSVideo from 'CMSVideo';
import CMSParallax from 'CMSParallax';
import SlickCarousel from 'SlickCarousel';
import { BackgroundImage } from "react-image-and-background-image-fade";

import Iframe from 'Iframe';
import GoogleMap from 'GoogleMap';
import IPLog from 'IPLog';
import AnimateCard from 'AnimateCard';
import FlipPhoto from 'FlipPhoto';
import ReactPixel from 'react-facebook-pixel';

// Animations if required
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

// ----- R E D U X ------
import { createReduxStore, setReduxValue } from 'ReduxStore';
import { persistStore, persistReducer } from 'redux-persist';
import { connect } from 'react-redux';
var reduxStore = createReduxStore();
persistStore(reduxStore);

class HomePopup extends React.Component {

	constructor(props, defaultProps) {
		super(props, defaultProps);
        this.state = {
					_popupState: true
        };
	}

	componentDidMount() {
		this._isMounted = true;

	}

	componentWillUnmount () {
      // Cancel any services to stop memory issues
			this._isMounted = false;
  }

	closeModal() {
		// console.log( 'Popup closed');
	}

	noMorePopups() {

		// console.log( 'No More Popups !!');

		if (this._isMounted)
				this.setState( {_popupState : false} );

		setReduxValue(reduxStore, 'SET_POPUP', 0);
		var action1 = {
				type: 'SET_POPUP',
				showPopup: 0
		};
		this.props.dispatch(action1);

	}

	render () {

		//
		//	Popups are always turned off for registered or logged-in users
		//

		//
		//		Only use cached images here as BackgroundImage takes forever for web-images
		//

		return (

			<div>
			{ this.props.userID < 1 && this.props.showPopup == 1 && <Popup
				open={this.state._popupState}
		    modal
		    closeOnDocumentClick
				onClose={this.closeModal}
		  >
				<BackgroundImage
					src='images/popup.jpg'
					className='image-cover'
					lazyload="true"
					>
		    <div className="popup-padding">

					<div className="text-center bold">
							<Translate text="As a first time caller to our website, please register with us for our newsletter." />
							<br /><br />
					</div>

					<Link to="/register">
						<div className="text-center">
							<button className="hollow button large secondary pink">
									<Translate text="Register" />
							</button>
						</div>
					</Link>

					<br />
					<div className="text-center bold">
							<Translate text="Click outside of this Popup to close" />
					</div>

					<br />
					<div className="text-center">
						<button className="hollow button secondary pink" onClick={ evt => this.noMorePopups(evt) }>
								<Translate text="Never show this Popup again" />
						</button>
					</div>

				</div>
				</BackgroundImage>

		  </Popup> }
			</div>
		);
	}

}

// Set the default values
HomePopup.defaultProps = {
	__currencySymbol: reduxStore.getState().__currencySymbol,
	locale: reduxStore.getState().locale,
	showPopup: reduxStore.getState().showPopup,
	userID: reduxStore.getState().userID
};

// Dynamic update is just prop-name: value from Redux store
function mapStateToProps(state) {
    return {
			__currencySymbol: state.__currencySymbol,
			locale: state.locale,
			showPopup: state.showPopup,
			userID: state.userID
    }
}

export default connect(mapStateToProps)(HomePopup);
